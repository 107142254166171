import { Component, EventEmitter, Input, Output } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { TenantSpecificTextService } from '../../../../../../../idv-lib/src/lib/services/tenant/tenant-specific-text.service';

@Component({
  selector: 'idv-additional-diseases-text',
  templateUrl: './additional-diseases-text.component.html',
  styleUrls: ['additional-diseases-text.component.scss'],
})
export class AdditionalDiseasesTextComponent {
  @Input()
  hasExistingDiseases: boolean;

  @Input()
  selectedProducts: Array<string>;

  @Output()
  allQuestionsConfirmed = new EventEmitter<boolean>();

  public paragraphOneText$: Observable<string>;
  public paragraphTwoText$: Observable<string>;
  public paragraphThreeText$: Observable<string>;
  public paragraphThreeAddOnText$: Observable<string>;

  public bulletPointsParagraphTwo$: Observable<Array<string>>;
  public bulletPointsParagraphThree$: Observable<Array<string>>;

  private _destroyed$ = new Subject<void>();

  public checkboxes: Array<{
    text: string;
    checked: boolean;
    bulletPoints: Array<string>;
    addOns: Observable<string>[];
  }>;

  constructor(private _tenantSpecificTextService: TenantSpecificTextService) {}

  ngOnInit() {
    this.paragraphOneText$ = this._tenantSpecificTextService.getText(
      'confirmCompletenessParagraphOne'
    );

    this.paragraphTwoText$ = this.getParagraphTwoText();
    this.paragraphThreeText$ = this._tenantSpecificTextService.getText(
      'confirmCompletenessParagraphThree'
    );
    this.paragraphThreeAddOnText$ = this._tenantSpecificTextService.getText(
      'paragraphThreeAddOn'
    );
    this.bulletPointsParagraphTwo$ =
      this._tenantSpecificTextService.getBulletPointsParagraphTwo();
    this.bulletPointsParagraphThree$ =
      this._tenantSpecificTextService.getBulletPointsParagraphThree();

    const paragraphTwo$ = combineLatest([
      this.paragraphTwoText$,
      this.bulletPointsParagraphTwo$,
      of(new Array<Observable<string>>()),
    ]);
    const paragraphThree$ = combineLatest([
      this.paragraphThreeText$,
      this.bulletPointsParagraphThree$,
      new Array([this.paragraphThreeAddOnText$]),
    ]);

    combineLatest(paragraphTwo$, paragraphThree$)
      .pipe(
        takeUntil(this._destroyed$),
        tap((paragraphs) => {
          this.checkboxes = paragraphs
            .filter((paragraph) => !!paragraph[0] || paragraph[1]?.length > 0)
            .map((val) => {
              return {
                text: val[0],
                checked: false,
                bulletPoints: val[1],
                addOns: val[2],
              };
            });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  updateCheckboxModel(checkboxIndex: number, checked: boolean) {
    this.checkboxes[checkboxIndex].checked = checked;
    this.allQuestionsConfirmed.emit(this.isAllChecked);
  }

  get isAllChecked() {
    return this.checkboxes.every((checkbox) => checkbox.checked === true);
  }

  private getParagraphTwoText(): Observable<string> {
    const overrideText$ = this._tenantSpecificTextService.getText(
      'confirmCompletenessParagraphTwoWithoutConditionsOverride'
    );

    const defaultText$ = this._tenantSpecificTextService.getText(
      'confirmCompletenessParagraphTwo'
    );

    return combineLatest([overrideText$, defaultText$]).pipe(
      map(([overrideText, defaultText]) => {
        if (overrideText && !this.hasExistingDiseases) {
          return overrideText;
        }
        return defaultText;
      })
    );
  }
}
